.teeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px;
}
.distance {
  font-weight:bold;
  font-size: 80pt;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
