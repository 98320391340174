.avatar {
  display: flex;
  width: 50px;
  height:50px;
  font-weight:500;
  font-size: 16pt;
  color:#ccc;
  background-color:#FFF;
  border-radius:25px;
  align-items:center;
  justify-content:center;
  margin-right:5px;
}
.lbRow {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;
  border-radius: 20px;
  padding: 15px;
}
.lbDisplayName {
  font-weight:bold;
  font-size: 14pt;
  color:#fff;
  justify-content: start;
  align-items: start;
  width:200px;
  display: flex;
}
.lbHometown {
  font-weight:500;
  font-size: 10pt;
  color:#ccc;
  justify-content: start;
  align-items: start;
  display: flex;
}
.lbPlace {
  font-weight:300;
  font-size: 10pt;
  color:#000;
  width:30px;
}
.lbScore {
  font-weight:900;
  font-size: 20px;
  color:#fff;
  justify-content: center;
  align-items: center;
}
