.welcomeMessage {
  font-weight:bold;
  font-size: 30pt;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
.subText {
  font-weight:400;
  font-size: 16pt;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
