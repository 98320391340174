body > #root > div {
  height: 100vh;
  width: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.container {
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
.label {
  font-size:8px;
}
.leaderboard {
  align-items: center;
  width:400px;
  height:100%;
  background-color: rgba(0,0,0,0.8);
}
.main {
  align-items: center;
  display: flex;
  width:80%;
  height:100%;
}
.screen {
  display: flex;
  flex-direction: row;
}
