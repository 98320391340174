.gameTitle {
  font-weight:bold;
  font-size: 30pt;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
.gameDescription {
  font-weight:400;
  font-size: 16pt;
  color:#fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
.gameIcon {
  font-size:60px;
  color:#098B54;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bg {
  background-size: cover;
  background-repeat: repeat;
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  position: absolute;
  left: 0;
  overflow: hidden;
  background-color:#333;
}
