.scoreFrame {
  background-color: rgba(0,0,0,0.9);
  border-radius: 20pt;
  align-items:center;
  justify-content:center;
  padding: 10pt;
  min-width:150pt;
  height:200;
  border-style:solid;
  border-width:5pt;
  margin-left:5pt;
  margin-right:5pt;
}

.score {
  font-size:60pt;
  color:#fff;
  text-align:center;
  font-weight:bold;
}

.scoreRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px;
}
.playerName {
  font-size:24pt;
  color:#fff;
  font-weight:bold;
}
